<script setup lang="ts">
import type TrackingSession from "~/dto/queue/TrackingSession";

const {trackingSessions} = storeToRefs(useTrackingStore());

const modalTrackingSession = ref<TrackingSession | null>(null);

watch(trackingSessions, (sessions: TrackingSession[]) => {
  if (
    modalTrackingSession.value
    && !sessions.find((session: TrackingSession) => session.id === modalTrackingSession.value!.id)
  ) {
    modalTrackingSession.value = null;
  }
});

const openModal = (session: TrackingSession) => {
  modalTrackingSession.value = session;
};
</script>

<template>
  <div
    v-if="trackingSessions.length > 0"
    class="row mb-2 justify-content-end"
  >
    <div
      v-for="session in trackingSessions"
      :key="session.id"
      class="col-12 col-md-7 col-lg-6 col-xl-5 col-xxl-4"
    >
      <NavbarTrackingSession
        :session="session"
        @open-modal="openModal"
      />
    </div>
    <NavbarTrackingModal
      :open="!!modalTrackingSession"
      :item="modalTrackingSession?.item"
      @close="modalTrackingSession = null"
    />
  </div>
</template>
