<script setup lang="ts">
import {CModal, CModalHeader, CModalTitle, CModalBody} from "@coreui/vue";
import QueueItem from "~/dto/queue/QueueItem";

const { t } = useI18n();

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  item: {
    type: Object as () => QueueItem,
    default: null,
  }
});

defineEmits(['close']);
</script>

<template>
  <CModal
    :visible="open"
    @close="$emit('close')"
  >
    <CModalHeader>
      <CModalTitle>{{ t('navbar.tracking.modal.title', {number: item.registrationNumber}) }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div
        v-if="item"
        class="text-center"
      >
        <div v-if="item.isArrived">
          <h2>{{ t('navbar.tracking.modal.queue_position') }}</h2>
          <div class="queue-position">
            {{ item.callingOrder }}
          </div>
        </div>
        <div
          v-else
          class="fs-1"
        >
          {{ t('navbar.tracking.modal.queue_called') }}
        </div>
        <div class="data fs-2">
          <div>
            <div class="float-start">
              <NavbarTrackingCheckpoint
                :checkpoint="item.checkpoint!"
                icon-size="icon-custom-size"
              />
            </div>
            <div class="float-end">
              <NavbarTrackingWaitingTime
                :item="item"
                icon-size="icon-custom-size"
              />
            </div>
          </div>
          <div class="clearfix" />
          <div class="text-center">
            <NavbarTrackingArrivedTime
              :item="item"
              icon-size="icon-custom-size"
            />
          </div>
        </div>
      </div>
    </CModalBody>
  </CModal>
</template>

<style lang="scss" scoped>
@import "@coreui/coreui-pro/scss/functions";
@import "@coreui/coreui-pro/scss/variables";
@import "@coreui/coreui-pro/scss/mixins";

.queue-position {
  font-size: 10rem;
  line-height: 8rem;
}

.data :deep(.icon-custom-size) {
  font-size: calc(1.3rem + 0.8vw);
}
@include media-breakpoint-up(xl) {
  .data :deep(.icon-custom-size) {
    font-size: 1.9rem;
  }
}
</style>