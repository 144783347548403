<script setup lang="ts">
import {CFormInput, CButton} from "@coreui/vue";

const store = useTrackingStore();
const toasts = useToastsStore();
const {isLookingNumber, isLoadingGuestTrackingSession, trackingSessions} = storeToRefs(store);

const registrationNumberValue = ref<string>('');
const isSearchDisabled = computed(() => registrationNumberValue.value.length <= 2 || isLookingNumber.value);

const { t } = useI18n();
const analytics = useAnalytics();

const find = () => {
  analytics.trackingUsing(registrationNumberValue.value);
  store.startTracking(registrationNumberValue.value)
      .then(session => {
        if (session) {
          analytics.trackingFound(registrationNumberValue.value);
        } else {
          toasts.addInfo(t('navbar.tracking.no_car_found'));
          analytics.trackingNotFound(registrationNumberValue.value);
        }
      })
      .catch((error) => {
        analytics.trackingFailed(registrationNumberValue.value);
        toasts.addError(t('error_occurred_try_later'));
        captureException(error);
      });
};
</script>

<template>
  <div
    v-if="isLoadingGuestTrackingSession"
    class="d-flex"
  >
    <IconLoading
      :size="24"
      class="success m-auto"
    />
  </div>
  <div
    v-else-if="0 === trackingSessions.length"
    class="d-flex"
  >
    <CFormInput
      v-model="registrationNumberValue"
      type="search"
      class="me-2"
      maxlength="10"
      :placeholder="$t('navbar.tracking.car_plate')"
      :disabled="isLookingNumber"
      @keyup.enter="find"
    />
    <CButton
      type="button"
      color="dark"
      variant="outline"
      class="d-inline-flex"
      :disabled="isSearchDisabled"
      @click="find"
    >
      <IconLoading
        v-if="isLookingNumber"
        :size="24"
        class="dark"
      />
      {{ $t('navbar.tracking.find') }}
    </CButton>
  </div>
</template>
