<script setup lang="ts">
import {CTooltip} from "@coreui/vue";
import QueueItem from "~/dto/queue/QueueItem";

const props = defineProps({
  item: {
    type: Object as () => QueueItem,
    required: true,
  },
  class: {
    type: String,
    default: null,
  },
  iconSize: {
    type: String,
    default: 'icon-lg',
  }
});
</script>

<template>
  <CTooltip :content="$t('navbar.tracking.modal.arrived_time_title', {time: item.registeredAt.toFormat('HH:mm, dd MMM')})">
    <template #toggler="{ on }">
      <div
        :class="props.class"
        v-on="on"
      >
        <i
          class="cil-input icon"
          :class="iconSize"
        /> {{ item.registeredAt.toFormat('HH:mm, dd MMM') }}
      </div>
    </template>
  </CTooltip>
</template>
